import "marketplaceStylesheets/index.css.scss";
import "whatwg-fetch";
import "storefront/lib/i18n";
import store from "storefront/lib/store";
import onboardingLocalStorageSubscriber from "storefront/Onboarding/localStorageSubscriber";
import { bindActions } from "../../containers/home";
import render from "../../components/Homepage/render";
import setUTMParamsCookie from "../../lib/setUTMParamsCookie";
import { hiring } from "../../lib/console_messages";
import initWebviewDOM from "../../lib/init_webview_dom";
import showFlash from "../../lib/show_flash";

hiring();
showFlash();
// Special setup for when the app is rendered in a webview in the app
initWebviewDOM();
setUTMParamsCookie();
const actions = bindActions(store);
// set the current "applied search id" using the window.location
actions.setCurrentAppliedSearchId();
// load the current user (using either document-data or making xhr)
actions.setCurrentUser();
// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Store<Record<string, any>, Actio... Remove this comment to see the full error message
store.subscribe(onboardingLocalStorageSubscriber(store));
const node = window.document.getElementById("app");
// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'HTMLElement | null' is not assig... Remove this comment to see the full error message
render(store, node);
