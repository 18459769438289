import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { isAfter, addDays } from "date-fns/fp";
import ContentfulPicture from "storefront/components/ContentfulPicture";
import { HeatwaveSiteBannerEntry } from "./types";

type Props = {
  entry: HeatwaveSiteBannerEntry;
};

/**
 * @name GlobalHeader.SiteBanner.HeatwaveSiteBanner
 * @description Site banner type for the Heatwave promotion
 */

const HeatwaveSiteBanner = ({ entry }: Props) => {
  const [dismissed, setDismissed] = useState<boolean>(true);

  const { ctaUrl, ctaLabel, title, image, urlExceptionsRegex, dismissable } =
    entry.fields;

  useEffect(() => {
    try {
      const expiration = window.localStorage.getItem(
        "heatwave_banner_expiration",
      );

      if (!expiration || isAfter(new Date(expiration))(new Date())) {
        window.localStorage.removeItem("heatwave_banner_expiration");
        setDismissed(false);
      }
    } catch (err) {}
  }, []);

  const handleDismiss = () => {
    try {
      const expiration = addDays(1)(new Date());
      window.localStorage.setItem(
        "heatwave_banner_expiration",
        expiration.toString(),
      );
    } catch (err) {}

    setDismissed(true);
  };

  if (
    urlExceptionsRegex &&
    window.location.pathname.match(urlExceptionsRegex)
  ) {
    return <div />;
  }

  return (
    <div
      className={classnames("Editorial--SiteBanner", {
        dismissed,
      })}
    >
      {ctaUrl ? (
        <a className="-cta" href={ctaUrl}>
          <ContentfulPicture
            className="-logo"
            alt="logo"
            images={[
              {
                src: image.fields.file.url,
                device: "desktop",
              },
            ]}
          />
          <span className="-title">{title}</span>
          <span className="-label">
            {ctaLabel}
            <span className="-arrow"> →</span>
          </span>
        </a>
      ) : (
        <div className="-cta">
          <ContentfulPicture
            className="-logo"
            alt="logo"
            images={[
              {
                src: image.fields.file.url,
                device: "desktop",
              },
            ]}
          />
          <span className="-title">{title}</span>
        </div>
      )}
      <span
        onClick={handleDismiss}
        className={classnames("-dismiss", {
          enabled: dismissable,
        })}
      >
        ✕
      </span>
    </div>
  );
};

export default HeatwaveSiteBanner;
