import Route from "route-parser";

export const pathWithoutTrailingSlash = (pathname: string): string => {
  if (pathname.length > 1 && pathname.slice(-1) === "/") {
    return pathname.slice(0, -1);
  }

  return pathname;
};

export const routeMatches = (spec: string): unknown => {
  const pathname = pathWithoutTrailingSlash(window.location.pathname);
  return new Route(spec).match(pathname);
};

let routed = false;

const route = (spec: string, cb: (prop: unknown) => unknown) => {
  if (routed) return;

  const result = routeMatches(spec);

  if (result) {
    routed = true;
    cb(result);
  }
};

export default route;
