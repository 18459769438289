import React, { lazy, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Footer from "storefront/components/Page/Footer";
import FullPageError from "storefront/components/FullPageError";
import SuspenseWithFragment from "storefront/components/SuspenseWithFragment";
import SuspenseWithLoading from "storefront/components/SuspenseWithLoading";
import GlobalHeader from "storefront/components/Page/Header";
import ViolationModal from "storefront/components/ViolationModal";
import SiteBanner from "storefront/components/SiteBanner";
import useAnalytics from "storefront/hooks/useAnalytics";
import useCurrentDevice from "storefront/hooks/useCurrentDevice";
import {
  shouldHideTrustStickyFooter,
  trustStickyFooterClosed,
} from "storefront/components/TrustStickyFooter";
import { requestWatcher } from "storefront/GrailedAPI/request/request";
import FlashManager from "storefront/lib/flash/FlashManager";
import { sentryInit, ErrorBoundary } from "../../lib/Sentry";
import NavOverlay from "../NavOverlay/NavOverlay";
import Contentful from "./Contentful";

sentryInit();

const SignupExperiment = lazy(
  () =>
    import(
      /* webpackChunkName: "SignupExperiment" */
      "storefront/components/SignupExperiment"
    ),
);

const TrustStickyFooter = lazy(
  () =>
    import(
      /* webpackChunkName: "TrustStickyFooter" */
      "storefront/components/TrustStickyFooter"
    ),
);

const Homepage = () => {
  const { track } = useAnalytics();
  const device = useCurrentDevice();

  useEffect(() => {
    const flashManager = FlashManager.getInstance();
    const unsubscribe = requestWatcher.subscribe((requestKey) => {
      flashManager.notice(`${requestKey}:  Too many requests!`);
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    track({
      object: "homepage",
      action: "viewed",
      properties: {
        nonInteraction: 1,
      },
    });
  }, [track]);

  return (
    <ErrorBoundary fallback={<FullPageError errorCode="unknown" />}>
      <BrowserRouter>
        <div id="homepage-v2">
          <div
            id="globalHeader"
            className="GlobalHeaderWrapper"
            style={
              device === "mobile"
                ? { position: "sticky", top: 0, zIndex: 220 }
                : {}
            }
          >
            <GlobalHeader />
          </div>

          <div id="siteBanner">
            <SiteBanner />
          </div>

          <div id="flash" />

          <ViolationModal />

          <div id="nav-overlay">
            <NavOverlay />
          </div>

          <ErrorBoundary fallback={<FullPageError errorCode="unknown" />}>
            <div className="homepage-wrapper">
              <SuspenseWithLoading>
                <Contentful />
              </SuspenseWithLoading>
            </div>
          </ErrorBoundary>

          {!trustStickyFooterClosed() && !shouldHideTrustStickyFooter() ? (
            <SuspenseWithFragment>
              <TrustStickyFooter />
            </SuspenseWithFragment>
          ) : null}

          <footer>
            <Footer />
            <SuspenseWithFragment>
              <SignupExperiment />
            </SuspenseWithFragment>
          </footer>
        </div>
      </BrowserRouter>
    </ErrorBoundary>
  );
};

export default Homepage;
