import React from "react";
import { prop } from "lodash/fp";
import { getContentfulConfigs } from "storefront/Config/PublicConfig";
import withContentful, {
  WithContentfulProps,
} from "storefront/Contentful/withContentful";
import usePublicConfig from "storefront/hooks/usePublicConfig";
import { SiteBannerEntry, SiteBannerModuleEntry } from "./types";
import HeatwaveSiteBanner from "./HeatwaveSiteBanner";

/**
 * @name GlobalHeader.SiteBanner.renderBanner
 * @description Renders a site-wide banner based on banner type
 */
const renderBanner = (entry: SiteBannerEntry) => {
  switch (entry.contentType) {
    case "heatwaveSiteBanner":
      return <HeatwaveSiteBanner entry={entry} />;

    default:
      return null;
  }
};

/**
 * @name GlobalHeader.SiteBanner
 * @description A global site-wide banner
 */
const SiteBanner = (props: WithContentfulProps<SiteBannerModuleEntry>) => {
  if (
    props.entry &&
    prop("entry.contentType", props) === "moduleSiteBanner" &&
    props.entry.fields.enabled
  ) {
    return (
      <div className="SiteBanner">
        {renderBanner(props.entry.fields.bannerContent)}
      </div>
    );
  }

  if (props.error) {
    return <></>;
  }

  return <div />;
};

const ContentfulBanner = withContentful<SiteBannerModuleEntry>(
  "moduleSiteBanner",
  SiteBanner,
);

export default () => {
  const publicConfig = usePublicConfig();

  const siteBannerId =
    publicConfig.contentful.spaces.grailed.entryIds?.sitebanner;

  const contentfulConfig = getContentfulConfigs(publicConfig);

  return (
    <ContentfulBanner
      query={{
        "sys.id": `${siteBannerId}`,
      }}
      config={contentfulConfig}
    />
  );
};
